body, html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #000;
}

.candle {
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
}

.flame {
    width: 50px;
    height: 50px;
    background: radial-gradient(circle, #ffbf00, transparent);
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 50%;
    border: 0px solid transparent;
    border-radius: 50%;
    transform: translateX(-50%);
    animation: flicker 3s infinite alternate;
}

@keyframes flicker {
    0% {opacity: 1;}
    50% {opacity: 0.6;}
    100% {opacity: 1;}
}
